const noClassName = 'demo-form-inline'
let isNoClassName = false

const Mixin = {
  mounted: function () {
    const vm = this
    document.onkeydown = function (e) {
      const key = window.event.keyCode
      if (key === 13) {
        for (const index in e.path) {
          const item = e.path[index]
          if (item.className) {
            isNoClassName = item.className.search(noClassName) >= 0
            if (isNoClassName) {
              break
            }
          }
        }
        if (isNoClassName) {
          vm.onSearch()
        }
      }
    }
  },
}

export default Mixin
