import { HttpPost } from '@/utils/request'

/**
 * 服务手机列表
 * @param options
 * @return {Promise<*>}
 */
export async function serviceMobileApi (options) {
  return await HttpPost('/service/mobile', options)
}

/**
 * 新增手机
 * @param options
 * @return {Promise<*>}
 */
export async function addMobileApi (options) {
  return await HttpPost('/service/addMobile', options)
}

/**
 * 手机详情
 * @param options
 * @return {Promise<*>}
 */
export async function mobileDetailApi (options) {
  return await HttpPost('/service/mobileDetail', options)
}

/**
 * 编辑手机
 * @param options
 * @return {Promise<*>}
 */
export async function editMobileApi (options) {
  return await HttpPost('/service/editMobile', options)
}
