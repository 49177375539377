<template>
  <el-tag v-if="data[state].label" :type="data[state].type || ''" :size="size" :effect="effect">{{data[state].label || ''}}</el-tag>
</template>

<script>
// TODO 未完成
export default {
  name: 'StatusTag',
  props: {
    state: {
      type: [Number, String],
      default: 0
    },
    size: String,
    effect: String,
    data: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped>

</style>
