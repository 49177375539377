<template>
  <div class="pagination">
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-sizes="pageSizes"
        :page-size.sync="limit"
        :layout="layout"
        :pager-count="pagerCount"
        :hide-on-single-page="hideOnSinglePage"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'PcPaginationView',
  props: {
    hideOnSinglePage: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    pageSizes: { // 每页显示个数选择器的选项设置,下拉选择框的选项值
      type: Array,
      default: () => ([10, 20, 30, 50])
    },
    pagerCount: { // 页码按钮的数量， 默认显示7个数，当总页数超过该值时会折叠,注意这里：它必须是 大于等于 5 且小于等于 21 的奇数
      type: Number,
      default: 7
    }
  },
  computed: {
    page: { // 计算属性返回当前页 （会改变）
      get () {
        return this.currentPage
      },
      set (val) {
        this.$emit('update:page', val)
      }
    },
    limit: { // 返回每页的限制条数，如每页30条，支持 .sync 修饰符实现同步更改
      get () {
        return this.pageSize
      },
      set (val) {
        // 当 pageSize 值发生更改时，在 computed 中会触发 pageSize 的 set 方法，修改父组件的 limit 值
        this.$emit('update:pageSize', val)
      }
    }
  },
  methods: {
    handleSizeChange (val) {
      this.$emit('size-change', val)
    },
    handleCurrentChange (val) {
      this.$emit('current-change', val)
    }
  }
}
</script>

<style scoped lang="scss">
.pagination {
  text-align: center;
  margin-top: 20px;
}
</style>
