<template>
  <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="show"
      custom-class="drawer"
      ref="drawer"
      size="50%"
  >
    <div class="demo-drawer__content">
      <el-form :model="drawerForm" label-width="100px">
        <el-form-item label="手机ID" v-if="drawerForm.mobile_id">
          <el-input disabled v-model="drawerForm.mobile_id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="drawerForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch
              v-model="drawerForm.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="mobileOnlineStatusEnum['1'].label"
              :inactive-text="mobileOnlineStatusEnum['2'].label"
              :active-value="1"
              :inactive-value="2"
          >
          </el-switch>
        </el-form-item>
        <slot />
      </el-form>
      <div class="demo-drawer__footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button v-indentify="'order:list:view:setServiceTime'" type="primary" @click="submit" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex'
import { addMobileApi, editMobileApi } from '@/api/service'

export default {
  name: 'pcServiceMobileDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    flag: {
      type: String,
      default: ''
    },
    drawerForm: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('statusTagEnum', ['mobileOnlineStatusEnum']),
    show: {
      get () {
        return this.$props.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      innerDrawer: false,
      smsList: [],
      sourceEnum: {
        1: '系统',
        2: '手动'
      },
      pre_send_time: ''
    }
  },
  methods: {
    // 关闭
    cancelForm () {
      this.loading = false
      this.show = false
      this.date = []
      this.$emit('cancel')
    },
    // 关闭前
    async handleClose (done) {
      if (this.loading) {
        this.$message.info('正在提交，请稍后再关闭！')
        return
      }
      await this.$confirm('确定关闭吗？')
      this.cancelForm()
    },
    async submit () {
      const { drawerForm, flag } = this.$props
      let data = null
      let msg = ''
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        if (flag === 'add') {
          data = await addMobileApi(drawerForm)
          msg = '添加'
        } else {
          data = await editMobileApi(drawerForm)
          msg = '编辑'
        }
        if (data.code === 1) {
          this.cancelForm()
          this.$message.success(msg + '订单成功！！！')
          this.$emit('confirm')
        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    }
  },
  watch: {
    drawerForm: {
      handler (newValue, oldValue) {
        this.drawerForm = newValue
      },
      deep: true
    }

  }
}
</script>

<style scoped lang="scss">
/deep/ .el-drawer__body {
  padding: 20px;
}
.demo-drawer__content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  form {
    flex: 1;
  }
  .demo-drawer__footer {
    display: flex;
     button {
      flex: 1;
    }
  }
}
</style>
