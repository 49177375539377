<template>
  <div class="container">
    <div>
      <div class="search-container">
        <el-form
            :inline="true"
            :model="formInline"
            @submit.native.prevent
            class="demo-form-inline"
        >
          <el-form-item label="手机号">
            <el-input v-model="formInline.mobile" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="在线状态">
            <el-select v-model="formInline.status" placeholder="请选择在线状态">
              <el-option
                  v-for="item of mobileOnlineStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button v-indentify="'service:mobileList:view:addMobile'" type="primary" icon="el-icon-plus"
                       @click="handle('add')">新增手机
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-container">
        <el-table
            :data="tableData"
            border
            style="width: 100%"
        >
          <el-table-column
              prop="id"
              label="手机ID">
          </el-table-column>
          <el-table-column
              prop="mobile"
              label="手机号码">
          </el-table-column>
          <el-table-column
              width="100"
              align="center"
              label="在线状态">
            <template slot-scope="scope">
              <div>
                <StatusTag :state="scope.row.status" :data="mobileOnlineStatusEnum"/>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="在线开始时间">
            <template slot-scope="scope">
              <span>{{ scope.row.active_start_time }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="在线结束时间">
            <template slot-scope="scope">
              <span>{{ scope.row.active_end_time }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="创建时间"
              width="190">
            <template slot-scope="scope">
              <span>{{ scope.row.create_time | dateFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="120"
              label="操作">
            <template slot-scope="scope">
              <el-button v-indentify="'service:mobileList:view:editMobile'" type="primary" size="small"
                         @click="handle('edit', scope.row.id)">编辑手机
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <PcPaginationView @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :total="total"
                        :page-size="page_size"/>
    </div>
    <PcServiceMobileDrawer
        :visible.sync="showDrawer" :title="handleFlag === 'add' ? '添加服务手机' : '编辑服务手机'" :drawerForm="drawerForm"
        :flag="handleFlag" @cancel="cancelDrawer" @confirm="confirmDrawer">
      <el-form-item label="在线时间">
        <el-time-select
            style="width: 100%"
            placeholder="起始时间"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
            }"
            v-model="drawerForm.active_start_time">
        </el-time-select>
      </el-form-item>
      <el-form-item label="在线时间">
        <el-time-select
            style="width: 100%"
            placeholder="结束时间"
            v-model="drawerForm.active_end_time"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
              minTime: drawerForm.active_start_time
            }">
        </el-time-select>
      </el-form-item>
    </PcServiceMobileDrawer>
  </div>
</template>

<script>
import Mixin from '@/utils/Mixin'
import StatusTag from '@/components/pc/common/StatusTag'
import { mapState } from 'vuex'
import PcPaginationView from '@/components/Pagination/pc'
import { mobileDetailApi, serviceMobileApi } from '@/api/service'
import PcServiceMobileDrawer from '@/components/pc/ServiceMobileDrawer'

export default {
  name: 'PcOrderList',
  mixins: [Mixin],
  components: {
    PcServiceMobileDrawer,
    StatusTag,
    PcPaginationView
  },
  computed: {
    ...mapState('selectOptions', ['mobileOnlineStatus']),
    ...mapState('statusTagEnum', ['mobileOnlineStatusEnum'])
  },
  data () {
    return {
      formInline: {
        mobile: '',
        status: ''
      },
      drawerForm: {
        mobile_id: '',
        mobile: '',
        status: 1,
        active_start_time: '',
        active_end_time: ''
      },
      date: [],
      page: 1,
      page_size: 10,
      total: 0,
      tableData: [],
      handleFlag: '',
      showDrawer: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const data = await serviceMobileApi({
        page: this.page,
        page_size: this.page_size,
        ...this.formInline
      })
      if (data.code === 1) {
        this.tableData = data.data.data
        this.total = data.data.total
      }
    },
    async getMobileDetail () {
      const { mobile_id } = this.drawerForm
      const data = await mobileDetailApi({ mobile_id })
      if (data.code === 1) {
        if (data.data === null) return
        this.drawerForm = data.data
        this.drawerForm.mobile_id = data.data.id
      }
    },
    async handle (flag, mobile_id = '') {
      if (flag === 'edit') {
        this.drawerForm.mobile_id = mobile_id
        await this.getMobileDetail()
      }
      this.showDrawer = true
      this.handleFlag = flag
    },
    cancelDrawer () {
      const { drawerForm } = this.$data
      for (const drawerFormKey in drawerForm) {
        drawerForm[drawerFormKey] = ''
        if (drawerFormKey === 'status') {
          drawerForm[drawerFormKey] = 1
        }
      }
      this.drawerForm = drawerForm
    },
    confirmDrawer () {
      this.getList()
    }, // 查询
    async onSearch () {
      this.page = 1
      await this.getList()
    },
    handleSizeChange (val) {
      this.page_size = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    /**
     * 时间区间change
     * @param date
     */
    dateHandle (date) {
      if (date) {
        this.formInline.start_time = date[0]
        this.formInline.end_time = date[1]
      } else {
        this.formInline.start_time = ''
        this.formInline.end_time = ''
      }
    }, // 时间戳转时间
    updateDrawerForm (date) {
      return this.$dateFormat(date, 'YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
<style lang="scss" scoped>

.flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}

.flex-left {
  justify-content: flex-start;
}

.flex-right {
  justify-content: flex-end;
}

.chat-content {
  max-width: 300px;
  word-break: break-all;
  font-size: 12px;
  margin: 0 10px;
}
</style>
